import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 1rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 1rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #333;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledIncDecButton = styled.button`
  padding: 10px;
  border-radius: 2px;
  margin: 0 2px;
  border: solid 1px #333;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 20px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  height: 100px;
  @media (min-width: 767px) {
    width: 500px;
    height: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledButtonReload = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 50%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkMini = styled.a`
  color: var(--secondary);
  font-size: 0.5rem;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click "Mint" to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [minted, setminted] = useState(0);
  const [mintedWl, setmintedWl] = useState(0);
  const [canFreeMint, setcanFreeMint] = useState(0);
  const [mintable, setMintable] = useState(0);
  const [whitelistProof, setwhitelistProof] = useState("");
  const [whitelistValid, setwhitelistValid] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const checkMinted = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const getWhitelist = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let whitelist = require("./data/whitelist.json");

      let validNuns = whitelist.map((addr) => addr.slice(-64));
      let adresses = whitelist.map((addr) => addr.slice(0, -64));

      let index = adresses.indexOf(address);
      let validNun = validNuns[index];

      let mintNum = 0;
      if (validNun !== undefined) {
        // mintNum = validNun.slice(-1);
        mintNum = parseInt(validNun, 16);
      }

      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address + validNun);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isWhitelisted(blockchain.account, mintNum, proof)
        .call()
        .then((receipt) => {
          setwhitelistProof(proof);
          setwhitelistValid(receipt);
          setcanFreeMint(mintNum);
          if (canFreeMint > 0) {
            setMintable(canFreeMint - mintedWl);
          }
          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setwhitelistProof(proof);
      // setwhitelistValid(valid);
      // console.log("WL:wallet/" + blockchain.account);
      console.log("WL:proof/" + proof);
      console.log("WL:root/" + root);
      // console.log(whitelistValid);
    }
  };

  const setReveal = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setReveal(true)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にリビールされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  // setPublicSaleEnable

  const setPublicSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にPBセール開始されました！")
            : setFeedback("正常にPBセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const setWLSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWhitelistSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にWLセール開始されました！")
            : setFeedback("正常にWLセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };
  const checkMintedwl = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedWl(receipt);
          if (canFreeMint > 0) {
            setMintable(canFreeMint - mintedWl);
          }
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const setBaseUrl = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ベースURL設定します ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setBaseURI(
        "https://arweave.net/bTGLjWcooZqswKZ-QluPgk2fXHJVJV4lTXNNCuG8yYI/"
      )
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にベースURL設定されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTswl = () => {
    let cost = 0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let mintableNum = canFreeMint - mintedWl;
    let totalCostWei = String(cost * mintableNum);
    // let totalGasLimit = String(gasLimit * mintAmountwl);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    console.log(canFreeMint, whitelistProof);
    blockchain.smartContract.methods
      .whitelistMint(mintableNum, canFreeMint, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMintedwl();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にmintされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount - diffNum;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount + diffNum;
    if (newMintAmount > CONFIG.MAX_MINT) {
      newMintAmount = CONFIG.MAX_MINT;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
      setMintAmount(1);
      setminted(0);
    }
    getData();
    getWhitelist();
    checkMinted();
    checkMintedwl();
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };

  useEffect(() => {
    getConfig();
    getData();
    checkMinted();
    checkMintedwl();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 0, backgroundColor: "#232330" }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
        image={isMobile ? "/config/images/pcbg.png" : "/config/images/pcbg.png"}
        // image={isMobile ? null : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/spacer.png"} />
        {/* <StyledLogo alt={"logo"} src={null} /> */}
        <ResponsiveWrapper
          flex={1}
          style={{ padding: 24, background: "" }}
          test
        >
          {/* {isMobile ? (<></>) : (<>

            <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/logo.png"} />
          </s.Container>
          </>)} */}
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <CircleTextLogo />
            {/* <StyledImg alt={"example"} src={"/config/images/logo.png"} /> */}
          </s.Container>

          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(255,255,255,0.7)",
              padding: 24,
              borderRadius: 24,
              // border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            {blockchain.account === "" ||
            blockchain.smartContract === null ||
            blockchain.account === undefined ? (
              <></>
            ) : (
              <>
                {data.deployer == blockchain.account ? (
                  <>
                    {!data.isWlEnabled ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setWLSaleEnable(true);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "WLセール開始(12日20時)"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setWLSaleEnable(false);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "WLセール停止(13日19時)"}
                        </StyledButtonOparatorOff>
                      </>
                    )}
                    <s.SpacerSmall />

                    {!data.isPsEnabled ? (
                      <StyledButtonOparator
                        onClick={(e) => {
                          e.preventDefault();
                          setPublicSaleEnable(true);
                          getData();
                        }}
                      >
                        {claimingNft ? "Busy" : "PBセール開始(13日20時)"}
                      </StyledButtonOparator>
                    ) : (
                      <>
                        <StyledButtonOparatorOff
                          onClick={(e) => {
                            e.preventDefault();
                            setPublicSaleEnable(false);
                            getData();
                          }}
                        >
                          {claimingNft ? "Busy" : "PBセール停止"}
                        </StyledButtonOparatorOff>
                      </>
                    )}

                    <s.SpacerSmall />

                    <StyledButtonOparator
                      onClick={(e) => {
                        e.preventDefault();
                        setBaseUrl();
                        getData();
                      }}
                    >
                      {claimingNft ? "Busy" : "URL設定(23日9時より前)"}
                    </StyledButtonOparator>

                    <s.SpacerSmall />
                    <StyledButtonOparator
                      onClick={(e) => {
                        e.preventDefault();
                        setReveal();
                        getData();
                      }}
                    >
                      {claimingNft ? "Busy" : "リビール(23日9時)"}
                    </StyledButtonOparator>

                    <s.SpacerSmall />

                    <StyledButtonOparator
                      onClick={(e) => {
                        e.preventDefault();
                        withdraw();
                        getData();
                      }}
                    >
                      {claimingNft ? "Busy" : "出金(任意)"}
                    </StyledButtonOparator>
                  </>
                ) : (
                  <></>
                )}{" "}
              </>
            )}

            <s.TextTitle style={{ textAlign: "center", color: "green" }}>
              NFT COLOR Xmas Premium COLLECTION
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "green" }}>
              AL Sale：12/12(月) 20:00〜
            </s.TextTitle>
            <s.TextTitle style={{ textAlign: "center", color: "green" }}>
              Public Sale：12/13(火) 20:00〜
            </s.TextTitle>

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  完売！！ありがとうございます！！今後ともよろしくお願いします。
                </s.TextTitle>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs
                </s.TextTitle>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {"AL:FREE"}
                </s.TextTitle>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {"Public:"}
                  {CONFIG.DISPLAY_COST}
                  {CONFIG.NETWORK.SYMBOL}
                  {"(∞Mint)"}
                </s.TextTitle>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {"(Max "}
                  {CONFIG.MAX_MINT}
                  {"mint/1 time)"}
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Excluding gas fees.（別途ガス代が必要です）
                </s.TextDescription>

                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ||
                blockchain.account === undefined ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {"接続アドレス："+blockchain.account}
                    </s.TextDescription> */}
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>

                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    ></s.TextDescription>
                    {data.isPsEnabled ||
                    (whitelistValid && data.isWlEnabled) ? (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 25,
                            fontWeight: "bold",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"Now On Sale!"}
                        </s.TextTitle>
                      </>
                    ) : (
                      <>
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: "bold",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"セール準備中..."}
                        </s.TextTitle>
                      </>
                    )}

                    {/* アローリスト所有者の方ここから */}
                    {whitelistValid ? (
                      <>
                        {data.isPsEnabled ? (
                          <></>
                        ) : (
                          <>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"あなたはアローリスト所有者です("}
                              {mintedWl}
                              {"済/"}
                              {canFreeMint}
                              {")"}
                            </s.TextDescription>
                          </>
                        )}

                        {data.isWlEnabled ? (
                          <>
                            <s.SpacerMedium />
                            {canFreeMint <= mintedWl ? (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"AL MINTありがとうございました"}
                                  </s.TextDescription>
                                </s.Container>
                              </>
                            ) : (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTswl();
                                      getData();
                                    }}
                                  >
                                    {claimingNft
                                      ? "BUSY"
                                      : canFreeMint - mintedWl + " MINT"}
                                  </StyledButton>
                                </s.Container>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"row"}
                                >
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"無料(ガス代のみ)"}
                                  </s.TextDescription>
                                </s.Container>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {/* {"アローリストセールは開始していません"} */}
                            </s.TextDescription>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"このアドレスはアローリストに含まれていません。"}
                        </s.TextDescription> */}
                      </>
                    )}
                    {/* ここからパブリックセール */}
                    <s.SpacerMedium />
                    {data.isPsEnabled ? (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"パブリックセール("}
                          {minted}
                          {"mint済)"}
                        </s.TextDescription>

                        {CONFIG.MAX_MINT == minted ? (
                          <>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"MINTいただきありがとうございました"}
                            </s.TextDescription>
                          </>
                        ) : (
                          <>
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledIncDecButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount(100);
                                }}
                              >
                                -100
                              </StyledIncDecButton>
                              <StyledIncDecButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount(10);
                                }}
                              >
                                -10
                              </StyledIncDecButton>
                              <StyledIncDecButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount(1);
                                }}
                              >
                                -1
                              </StyledIncDecButton>
                              <s.SpacerMedium />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                  padding: 20,
                                }}
                              >
                                {mintAmount}
                              </s.TextDescription>
                              <s.SpacerMedium />
                              <StyledIncDecButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount(1);
                                }}
                              >
                                +1
                              </StyledIncDecButton>
                              <StyledIncDecButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount(10);
                                }}
                              >
                                +10
                              </StyledIncDecButton>
                              <StyledIncDecButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount(100);
                                }}
                              >
                                +100
                              </StyledIncDecButton>
                            </s.Container>
                            <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                              >
                                {claimingNft ? "BUSY" : mintAmount + " MINT"}
                              </StyledButton>
                            </s.Container>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {"パブリックセールは開始していません"}
                        </s.TextDescription>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />

            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              NFT Market Place
            </s.TextDescription>
            <s.SpacerSmall />
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              {CONFIG.MARKETPLACE}
            </StyledLink>
            <s.SpacerLarge />

            <s.Container ai={"center"} jc={"center"} fd={"row"}>
              <StyledButtonReload
                onClick={(e) => {
                  e.preventDefault();
                  getReload();
                }}
              >
                {claimingNft ? "Busy" : "RELOAD"}
              </StyledButtonReload>
            </s.Container>
          </s.Container>
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/circle.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container> */}
          {/* {isMobile ? (<></>) : (<>
            <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/logo.png"} />
          </s.Container>
          </>)} */}
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/* <StyledImg alt={"example"} src={"/config/images/logo.png"} /> */}
            <CircleTextLogo />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary)",
            }}
          >
            正しいネットワークに接続されていることを確認してください (
            {CONFIG.NETWORK.NAME}{" "}
            Mainnet)と正しいアドレスに接続されていることを確認してください。ご注意ください。
            一度購入すると、この操作を取り消すことはできません。
          </s.TextDescription>
          <StyledLink target={"_blank"} href={"https://twitter.com/Nftcolor22"}>
            Founder: @NFTCOLOR
          </StyledLink>
          <s.SpacerMedium />

          {/* <StyledLinkMini
            target={"_blank"}
            href={"https://twitter.com/freakyyyy_frick"}
          >
            Code: @FRICKLIK
          </StyledLinkMini> */}
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
